import * as React from "react";
import { useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import RightIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import EventIcon from "@mui/icons-material/Event";
import PhoneIcon from '@mui/icons-material/Phone';
import FormModal from "../organisms/FormModal";

const LeadButton = (props) => {
    const [openModal, setOpenModal] = useState<boolean>(false)
    const variant2 = props.website?.live?.functions?.form?.disabled ? "contained" : "outlined"
    const variant3 = !props.website?.live?.functions?.calendly?.redirectTo ? props.website?.live?.functions?.form?.disabled ? "contained" : "text" : "text"
    const buttonShape = 
        props.website.live.layout.buttonShape === "pill" ? 10 
        : props.website.live.layout.buttonShape === "rounded" ? 2
        : 0
    
    const buttonProps = {
        borderRadius: buttonShape,
        maxWidth: "300px",
        fontWeight: "bold",
        padding: {
            xs: "8px 16px 8px 16px",
            md: "16px 32px 16px 32px",
        }
    }
    if (!props.website?.live?.functions?.actions?.includes("Leads")) {
        return <></>
    } else {
        return <>
            <Stack direction={{ xs: "column", sm: "row" }}
                spacing={2}
            >
                {!props.website?.live?.functions?.form?.disabled &&
                    <Button
                        key={"button-form"}
                        variant={"contained"}
                        sx={buttonProps}
                        color="secondary"
                        onClick={() => {
                            setOpenModal(true)
                        }}
                        endIcon={<RightIcon />}
                    >
                        {props.website?.live?.functions?.form?.buttonText}
                    </Button>
                }
                {props.website?.live?.functions?.phone?.enabled && props.website?.live?.functions?.phone?.redirectTo && <Button
                    key={"button-phone"}
                    variant={variant2}
                    style={{ border: variant2 === "outlined" ? "2px solid" : "" }}
                    size={"large"}
                    sx={buttonProps}
                    color={"secondary"}
                    href={"tel:".concat(props.website?.live?.functions?.phone.redirectTo.replace('tel:',''))}
                    endIcon={<PhoneIcon />}
                >
                    {props.website?.live?.functions?.phone?.buttonText}
                </Button> || <></>}
                {props.website?.live?.functions?.calendly?.enabled && props.website?.live?.functions?.calendly?.redirectTo && <Button
                    key={"button-calendly"}
                    variant={variant2}
                    style={{ border: variant2 === "outlined" ? "2px solid" : "" }}
                    size={"large"}
                    sx={buttonProps}
                    color={"secondary"}
                    href={"https://".concat(props.website?.live?.functions?.calendly.redirectTo.replace('https://','').replace('http://',''))}
                    target={"_blank"}
                    endIcon={<EventIcon />}
                >
                    {props.website?.live?.functions?.calendly?.buttonText}
                </Button> || <></>}
                {props.website?.live?.functions?.link?.enabled && props.website?.live?.functions?.link?.redirectTo && <Button
                    key={"button-link"}
                    variant={variant3}
                    sx={buttonProps}
                    color="secondary"
                    href={"https://".concat(props.website?.live?.functions?.link.redirectTo.replace('https://','').replace('http://',''))}
                    target={"_blank"}
                >
                    {props.website?.live?.functions?.link?.buttonText}
                </Button> || <></>}
            </Stack>
            <FormModal
                open={openModal}
                siteId={props.website.jsonId}
                formDefinition={props.website?.live?.functions?.form}
                layout={props.website?.live?.layout}
                closeModal={() => {
                    setOpenModal(false)
                }}
            />
        </>
    }
}
export default LeadButton;